import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Menu from '../components/menu'
import { rhythm } from "../utils/typography"
import styled from "styled-components"

const ArticleHeader = styled.h3`
  margin-bottom: ${rhythm(1 / 4)};
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: block;

    & small {
      margin-top: ${rhythm(1/4)};
      display: block;
    }
  }
`

const ArticleWrapper = styled.div`
  border-bottom: 1px solid #eee;
  margin-bottom: ${rhythm(1/2)};
`

const StyledLink = styled(Link)`
   box-shadow: none;
   font-weight: 400;
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const imageSrc = data.fileName.childImageSharp.fixed.src

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO image={imageSrc} />
        <Menu/>
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <ArticleWrapper key={node.fields.slug}>
              <ArticleHeader>
                <StyledLink to={node.fields.slug}>
                  {title}
                </StyledLink>
                <small>{node.frontmatter.date}</small>
              </ArticleHeader>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </ArticleWrapper>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    fileName: file(
      relativePath: { eq: "profile-pic.jpg" },
      sourceInstanceName: {
	eq: "assets"
      }
    ) {
      childImageSharp {
	fixed(width: 876) {
	  src
	}
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { draft: { eq: false } } } # add
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
