/**
 * Menu component that queries for data
 *
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { rhythm } from "../utils/typography"

const liStyle = {
  display: 'inline-block',
  marginLeft: rhythm(1)
}

function Menu() {
  return (
    <StaticQuery
      query={menuQuery}
      render={data => {
        return (
          <ul
            style={{
              listStyleType: 'none'
            }}
          >
            {data.site.siteMetadata.menu.map((el, index) => {
              return (
                <li style={
                  index === 0 ? { display: 'inline-block' } :
                  liStyle
                }
                key={index}
              >
                  <a href={el.link}>{el.title}</a>
                </li>
              )
            })}
          </ul>
        )
      }}
    />
  )
}

const menuQuery = graphql`
  query MenuQuery {
    site {
      siteMetadata {
        menu {
          title
          link
        }
      }
    }
  }
`

export default Menu
